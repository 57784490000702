import { Text } from '@chakra-ui/react'

const questionsData = () => {
  return [
    {
      title: 'Comment ont été sélectionnés les projets ?',
      text: (
        <Text as="ul" style={{ listStyleType: 'disc' }} size="md">
          Les projets ont été sélectionnés par les équipes de MiiMOSA et innocent selon les critères suivants :
          <Text as="li" ml="4">
            un besoin de financement pour de la plantation arboricole
          </Text>
          <Text as="li" ml="4">
            la protection de la biodiversité
          </Text>
          <Text as="li" ml="4">
            la mise en place de pratiques conciliant production agricole et protection des ressources naturelles
          </Text>
          <Text as="li" ml="4">
            la résilience face aux aléas climatiques
          </Text>
          <Text as="li" ml="4">
            un siège social en France
          </Text>
        </Text>
      ),
    },
    {
      title: 'Qu’est-ce que le financement participatif ?',
      text: (
        <Text size="md">
          Le financement participatif (crowdfunding en anglais) est un mode de financement permettant au grand public de
          soutenir financièrement et collectivement des projets qui le séduisent. MiiMOSA utilise deux offres de
          financement participatif : le don avec contrepartie et le prêt rémunéré.
        </Text>
      ),
    },
    {
      title: 'MiiMOSA, c’est quoi ?',
      text: (
        <Text size="md">
          MiiMOSA est la plateforme de financement leader en Europe dédiée à la transition agricole et alimentaire.
          Après avoir accompagné 7 500 projets pour 180 millions d’euros de financement (en France et en Belgique),
          MiiMOSA a lancé en janvier 2022 le premier fonds de dette dédié à la thématique. <br />
          <br />
          Labellisée BCorp, l’entreprise s’engage à recréer du lien entre des porteurs de projet et l’ensemble de la
          société, afin que chacun participe à la résolution des enjeux alimentaires, environnementaux, climatiques,
          sanitaires et énergétiques.
        </Text>
      ),
    },
  ]
}

export default questionsData
